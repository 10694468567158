
import { Vue, Component } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { Getter } from 'vuex-class';
import PostsAndCommentApi from '@/module/api/PostsAndCommentApi';
import PostId from '@/model/posts/PostId';
import BrowsingStatus from '@/model/posts/comment/BrowsingStatus';

Component.registerHooks(['beforeRouteEnter']);
@Component({})
export default class ReadedUserList extends Vue {
  @Getter('isAdminGroup') isAdminGroup!:boolean;
  postId!:PostId;
  browsingStatusList:BrowsingStatus[]=[];
  readedStatus='all';
  searchName='';
  hasLimit:boolean = false;

  mounted():void{
    PostsAndCommentApi.searchStatus(this.postId).then((res) => {
      this.browsingStatusList = res.data;
    });
  }
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<ReadedUserList>): void {
    next((vm: ReadedUserList) => vm.initialize(to));
  }
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext<ReadedUserList>): void {
    this.initialize(to);
    next();
  }
  initialize(to: Route): void {
    this.postId = new PostId(Number(to.params.postId));
    this.hasLimit = to.params.hasLimit === 'true';
  }
  get filteredList():BrowsingStatus[] {
    let result = this.browsingStatusList;
    if (this.readedStatus === 'kidoku') {
      result = result.filter((b) => b.openDate.value);
    }
    if (this.readedStatus === 'midoku') {
      result = result.filter((b) => b.openDate.value === null);
    }
    if (this.searchName !== '') {
      result = result.filter((b) => b.userName.value.match(this.searchName));
    }
    return result;
  }
}
